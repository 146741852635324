<template>
  <div class="col main_localization_wrapper_container" v-loading="isLoading">
    <!-- Start Tabs  -->
    <div>
      <card>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-4">
              <div class="p-2">
                <el-select
                  v-model="localization_id"
                  :placeholder="`${localization('Age')}`"
                  class="custom_select w-100"
                >
                  <el-option
                    v-for="(item, index) in localizationList"
                    :key="index"
                    :label="item.language"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </div>
              <div class="export_import_buttons_wrapper mt-2 mb-2">
                <button
                  class="btn_wrapper import"
                  v-tooltip.top-center="'Import'"
                  style="width: 100%; height: 150px;"
                >
                  <el-upload
                    :on-success="RefreshLocalizationForImport"
                    accept=".xlsx"
                    :show-file-list="false"
                    :action="import_src"
                    :on-progress="beforeUpload"
                    :before-upload="beforeUpload"
                  >
                    <el-icon class="el-icon-upload localization_icon"></el-icon>
                  </el-upload>
                </button>

                <button
                  class="btn_wrapper export"
                  @click="exportLocalization"
                  v-tooltip.top-center="'Export'"
                  style="width: 100%; height: 150px;"
                >
                  <el-icon class="el-icon-download localization_icon"></el-icon>
                </button>
              </div>
              

              <!-- <el-button
                class="label_custom_img m-3"
                :class="{ processing: processing }"
                @click="save"
                type=""
              >
                <span v-if="!processing">Save</span>
                <div class="loader_ocr" v-if="processing"></div>
              </el-button> -->
            </div>
          </div>
        </div>
      </card>
    </div>
    <!-- End Tabs  -->
  </div>
</template>

<script>
import { Card } from "src/components/index";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      processing: false,
      import_url: "",
      isLoading: false,
      fileList: [],
      activeName: "English2",
      mainLocalization: [],
      localization_id: 2,
      localizationTranslationObject: {
        // Start Sidebar
        "#separator#1": "Sidebar",
        "User Dashboard": "User Dashboard",
        "Admin Dashboard": "Admin Dashboard",
        "My Account": "My Account",
        "Edit Account": "Edit Account",
        "Edit LCI": "Edit LCI",
        "Personal Learning Profile": "Personal Learning Profile",
        Institutions: "Institutions",
        Management: "Management",
        Teams: "Teams",
        Logout: "Logout",
        Contacts: "Contacts",
        Groups: "Groups",
        "Use Your Patterns": "Use Your Patterns",
        Users: "Users",
        Videos: "Videos",
        "My Profile": "My Profile",
        "how will lci help you": "How Will The LCI Help You?",
        "lci tool tip":
          "Complete the Learning Connection Inventory and discover how your mind helps you learn, complete tasks, and work with others.",
        "plp tool tip":
          "Your Personal Learning Profile results will show, differentiating your learning patterns by type/number",
        "contacts tool tip":
          "Invite your classmates, friends, business, and school contacts to share their LCI scores with you and build your opportunity to share strategies!",
        "groups tool tip":
          "Invite your classmates, friends, business, and school contacts to share their LCI scores with you and build your opportunity to share strategies!",
        "teams tool tip": "teams tool tip",
        "how will the lci help tool tip": "how will the lci help tool tip",
        "videos tool tip": "videos tool tip",
        "edit account tool tip": "edit account tool tip",
        "use my pattern tool tip":
          "Select the personal strategies you need to succeed in completing and learning, work, or team assignment!",
        "use my pattern at work tool tip": "use my pattern at work tool tip",
        "use my pattern career match tool tip":
          "use my pattern career match tool tip",
        "In School": "In School",
        "At Work": "At Work",
        "Career Match": "Career Match",
        // End Sidebar

        // Start User Dashboard Page
        "#separator#2": "User Dashboard",
        LCI: "LCI",
        "Use Your Learning Patterns": "Use Your Learning Patterns",
        Contacts: "Contacts",
        "Groups/Teams": "Groups/Teams",
        "LCI Text":
          "Complete the Learning Connection Inventory and  discover how your mind helps you learn, complete tasks, and work with others!.",
        "Personal Learning Text":
          "Build your unique Personal Learning Profile to share with team members, instructors, even potential employer!.",

        "Learning Patterns Text":
          "Select the personal strategies you need to succeed in completing any learning, work, or team assignment!.",

        "Contacts Text":
          "Invite your classmates, friends, business and school contacts to share their LCI scores with you and build your opportunity to share strategies!.",

        "Groups/Teams Text":
          "Use the PLC to help you build effective  teams where your teammates understand each other and commit to learn together so, when necessary, they can each perform alone!.",
        "coaching card": "coaching  card content",
        "profile card": "profile  card content",
        "lci card": "lci  card content",
        "pattern card": "pattern  card content",
        "team card": "team  card content",
        // Start User Dashboard Page

        // Start Admin Dashboard Page
        "#separator#3": "Admin Dashboard",
        Activity: "Activity",
        Tasks: "Tasks",
        Individuals: "Individuals",
        Students: "Students",
        "Institutional Users": "Institutional Users",
        // End Admin Dashboard Page

        // Start Profile
        "#separator#4": "Profile",
        "Share on twitter": "Share on twitter",
        "Share on facebook": "Share on facebook",
        "Edit score": "Edit score",
        "Print profile": "Print profile",
        "Share your profile": "Share your profile",
        "Personalize Your Profile": "Personalize Your Profile",
        "Add statement": "Add statement",
        "Delete statement": "Delete statement",
        "Read out loud": "Read out loud",
        "Sync Google classroom": "Sync your account to google classroom",
        "Sync On": "Sync On",
        "Type a statement": "Type a statement",
        Statement: "statement",
        "Type Email": "Type Email",
        "Password does not match": "Password does not",
        "share profile confirmation": "share profile confirmation",
        "score profile confirmation": "score profile confirmation",
        "User type dynamic": "Dynamic Learner",
        "user type bridge": "Bridge Learner",
        "user type SWL": "Strong-Willed Learner",
        "dynamic tool tip":
          "Dynamic is the combination of Learning Patterns that uses at least one Pattern at the Use First level, while the remaining ones are any combination of another Use First, Use as Needed, or Avoid. You experience yourself shifting from the use of one Pattern to another, particularly when you move from your Use First Patterns to those you Avoid. As a Dynamic learner, you can relate to others well and understand how they use their Learning Patterns. You can identify when a colleague or supervisor is shifting from one Pattern to another and are able to sense when others in your work setting are having their Pattern needs ignored.",
        "bridge tool tip":
          "Bridge is the combination of Learning Patterns that features no Use First or Avoid but instead consists of all four Patterns at a Use as Needed level. If all of your Pattern scores fall between 18 and 24, you have a combination of Learning Patterns that bridge the difference between a Use First and an Avoid level of a Pattern, creating the opportunity for you to work seamlessly to connect and solve learning challenges. Your Patterns serve a helpful role by leading from the middle. Bridge learners are team catalysts who, by listening and interacting with others, bring people closer to resolution while eliminating grandstanding and arguing. The Bridge learner can close the gap of understanding between learners with conflicting Patterns by bridging the distance between their Use First and Avoid Patterns.",
        "swl to tip":
          "Strong-Willed is the combination of Learning Patterns that uses three or more Patterns at the Use First level and the remaining Pattern at the Use as Needed or Avoid level. Strong-Willed learners seek opportunities to lead rather than be led. Their Pattern combination of three or more Use First Learning Patterns positions them to be their own self-contained team. Do you prefer to control the plan, the ideas, the talks, the decisions, the processes, and the outcomes? If so, your Patterns are likely putting you into situations that make it difficult for you to work with others because you could (and would prefer to) do it all yourself. Understanding yourself as a learner and being aware of how your approach affects those around you is particularly important when you have a Strong-Willed Pattern combination.",
        definition: "Definition",
        "plp print": "plp print",
        "plp share": "plp share",
        "Start with the LCI": "Start with the LCI",
        "Start LCI": "Start LCI",

        // End Profile

        // Start Edit Account
        "#separator#5": "Edit Account",
        "Complete your profile": "Complete your profile",
        "Personal Info": "Personal Info",
        "Public Profile": "Public Profile",
        "Google Classroom": "Google Classroom",
        "Institution Info": "Institution Info",
        "My Institution": "My Institution",
        "Institution Name": "Institution Name",
        "Institution Email": "Institution Email",
        "Institution Contact": "Institution Contact",
        "Join Institution": "Join Institution",
        "Leave Institution": "Leave Institution",
        "Change Password": "Change Password",
        "Change User Password": "Change User Password",
        "Billing Info": "Billing Info",
        "Change My Info": "Change My Info",
        "Unsync class": "Unsync class",
        "Sync Class": "Sync Class",
        "Process Payment": "Process Payment",
        "subscribing to Personal Learning Coach":
          "By adding a credit card you are subscribing to PersonalLearningCoach.com and will be billed annually.",
        "Institution Promo Code": "Enter Institution Promo Code",
        "Institution  not found": "Institution  not found",
        "You cannot join this institution , this institution has reached the user limit":
          "You cannot join this institution , this institution has reached the user limit",
        // End Edit Account

        // Start Edit Account Forms
        "#separator#6": "Edit Account Forms",
        Age: "Age",
        Language: "Language",
        "ZIP / Postal Code": "ZIP / Postal Code",
        Country: "Country",
        password: "password",
        "Confirm Password": "Confirm Password",
        "Please enter Password": "Please enter Password",
        "Show Password": "Show Password",
        "Hide Password": "Hide Password",
        "Card Name": "Card Name",
        "Card Number": "Card Number",
        "Expiration Date": "Expiration Date",
        CVC: "CVC",
        "Auto Renew": "Auto Renew",
        // End Tables And Forms

        // Start LCI Questions
        "#separator#7": "Edit LCI",
        "Learning Connections Inventory": "Learning Connections Inventory®",
        "Part I": "Part I",
        "Part II": "Part II",
        "Submit LCI": "Submit LCI",
        Answer: "Answer",
        "LCI Questions alert":
          "This is a read only page. Only user can edit his PLC",
        "thank you for subscription title":
          "THANK YOU FOR YOUR SUBSCRIPTION TO THE PERSONAL LEARNING COACH",
        "to get started": "TO GET STARTED",
        "Please click the button below":
          "Please click the button below to complete the Learning Connections Inventory (LCI). After, please fill out and complete your Personal Learning Profile.",
        "take your lci here": "take your lci here",
        "explore each feature":
          "EXPLORE EACH FEATURE OF THE PERSONAL LEARNING COACH",
        // End LCI Questions

        // Start Use Your Patterns
        "#separator#8": "Use Your Patterns",
        "Personal Strategies Instructions": "Personal Strategies Instructions",
        "Edit personal strategy": "Edit personal strategy",
        "Show personal strategy": "Show personal strategy",
        "Delete personal strategy": "Delete personal strategy",
        "Add personal strategy": "Add personal strategy",
        "Print personal strategy": "Print personal strategy",
        "Share personal strategy": "Share personal strategy",
        "Extract from photo": "Extract from photo",
        "Personal strategy description": "Personal Strategy Description",
        "Add strategy": "Add strategy",
        "Show more": "Show more",
        "Show less": "Show less",
        Strategy: "Strategy",
        "Please enter a strategy": "Please enter a strategy",
        "Check Extracted Photo": "Please double check the extracted text!",
        "Name Your Task": "Name Your Task",
        "Select Your task's topic":
          "Select Your task's topic from the dropdown",

        "Type in, or copy and paste(in school)":
          "Type in, or copy and paste your directions for your assignment",
        "upload images(in school)":
          "You can also upload a jpeg, png or pdf of the directions and click UPLOAD",

        "Type in, or copy and paste(at work)":
          "work-Type in, or copy and paste your directions for your assignment",
        "upload images(at work)":
          "You can also upload a jpeg, png or pdf of the directions and click UPLOAD",

        "Type in, or copy and paste(career match)":
          "career-Type in, or copy and paste your directions for your assignment",
        "upload images(career match)":
          "You can also upload a jpeg, png or pdf of the directions and click UPLOAD",
        "Add job instructions animation":
          "Click on the 'Action' button and then Add personal strategy then follow the steps",
        "Add at work instructions animation":
          "Click on the 'Action' button and then Add personal strategy then follow the steps",

        "Add A Task": "Add A Task",
        "Edit A Task": "Add A Edit",
        "Add task instructions": "Add task instructions",
        "Add task instructions text": "Add task instructions text",
        Start: "Start",
        "Add task instructions animation":
          "Click on the 'Action' button and then Add personal strategy then follow the steps",
        "add task": "add task",
        mode: "mode",
        "add job": "Add Job",
        "edit job": "Edit Job",
        "Name Your Job": "Name Your Job",
        "Select Your job's topic": "Select Your job's topic from the dropdown",
        "show job": "Show Job",
        "Delete job": "Delete Job",
        "Print job": "Print Job",
        "Share job": "Share Job",
        "Add job strategy": "Add job strategy",
        // End Use Your Patterns

        // Start Contacts
        "#separator#9": "Contacts",
        "Add New Contact": "Add New Contact",
        "Accept request": "Accept request",
        "Delete contact": "Delete contact",
        "Restored Contacts": "Restored Contacts",
        "Invite All Contacts": "Invite All Contacts",
        "Reinvite contact": "Reinvite contact",
        "get your link": "get your link",
        "Share link": "Share this link to add contacts",
        "My Contacts": "My Contacts",
        "Pending Requests": "Pending Requests",
        "Received Requests": "Received Requests",
        // End Contacts

        // Start Groups
        "#separator#10": "Groups",
        "Add New Group": "Add New Group",
        "Add a new member": "Add a new member",
        "Edit group name": "Edit group name",
        "Export group": "Export group",
        "Delete group": "Delete group",
        "Delete member": "Delete member",
        "Add Additional Members": "Add Additional Members",
        Members: "Members",
        "Select Members": "Select Members",
        // End Groups

        // Start Users
        "#separator#11": "Users",
        "View profile": "View profile",
        "Delete user": "Delete user",
        "Active user": "Active user",
        Institution: "Institution",
        "Push Task": "Push Task",
        "Add Category": "Add Category",
        "Choose tags for your article": "Choose tags for your article",
        "No Category": "No Category",
        // End Users

        // Start Institutions
        "#separator#12": "Institutions",
        "Add Institution": "Add Institution",
        "Show Institution": "Show Institution",
        "Edit Institution": "Edit Institution",
        "Delete Institution": "Delete Institution",
        "Activate Institution": "Activate Institution",
        "Edit Institutions Info": "Edit Institutions Info",
        "Promo Code": "Promo Code",
        "Of Seats": "Of Seats",
        "Contact Name": "Contact Name",
        Notes: "Notes",
        "Please select category": "Please select category",
        Phone: "Phone",
        "Make A Payment": "Make A Payment",
        "Card Holder Name": "Card Holder Name",
        "Credit Card Number": "Credit Card Number",
        "Credit Card Expiration Date": "Credit Card Expiration Date",
        CVC: "CVC",
        Amount: "Amount",
        Status: "Status",
        Active: "Active",
        Inactive: "In active",
        // End Institutions

        // Start Management
        "#separator#13": "Management",
        "LCI Questions": "LCI Questions",
        Strategies: "Strategies",
        Words: "Words",
        Topics: "Topics",
        Attributes: "Attributes",
        "Website CMS": "Website CMS",
        "Roles & Permissions": "Roles & Permissions",
        Logs: "Logs",
        "Team Statements": "Team Statements",
        "Statements Questions": "Statements Questions",
        Languages: "Languages",
        localization: "localization",
        "Task Categories": "Task Categories",
        "LCI error message":
          "The responses you chose do not reveal your combination of Learning Processes.  For more information, please contact us at info@letmelearn.org..",
        // End Management

        // Start LCI Questions // Questionnaire
        "#separator#14": "LCI Questions",
        "Add Version": "Add Version",
        "Add Question": "Add Question",
        "Edit Question": "Edit Question",
        Question: "Question",
        "Show question": "Show question",
        "Edit question": "Edit question",
        "Delete question": "Delete question",
        "Active question": "Active question",
        "Please select type": "Please select type",
        "Please select Age group": "Please select Age group",
        "Please select a pattern": "Please select a pattern",
        "Please select language": "Please select language",
        "Add answer": "Add answer",
        "Add Bulk": "Add Bulk",
        "Select file": "Select file",
        "Unique answers": "Kindly input uniques answers",
        "Answer all questions": "Please answer all questions",
        "LCI error message":
          "The responses you chose do not reveal your combination of Learning Processes. For more information, please contact us at info@letmelearn.org..",
        // End LCI Questions // Questionnaire

        // Start Strategies
        "#separator#15": "Strategies",
        "Show strategy": "Show strategy",
        "Edit strategy": "Edit strategy",
        "Delete strategy": "Delete strategy",
        "Active strategy": "Active strategy",
        "Strategy Type": "Strategy Type",
        "Admin Strategy": "Admin Strategy",
        "User Strategy": "User Strategy",
        "Age Group": "Age Group",
        "Please select a classification": "Please select a classification",
        "Please select a topic": "Please select a topic",
        Info: "Info",
        "Edit Version": "Edit Version",
        // End Strategies

        // Start Words
        "#separator#16": "Words",
        "Add Word": "Add Word",
        "Show Word": "Show Word",
        "Edit Word": "Edit Word",
        "Delete Word": "Delete Word",
        "Active Word": "Active Word",
        "Please enter word": "Please enter word",
        // End Words

        // Start Topics
        "#separator#17": "Topics",
        "Add Topic": "Add Topic",
        "Show Topic": "Show Topic",
        "Edit Topic": "Edit Topic",
        "Delete Topic": "Delete Topic",
        "Active Topic": "Active Topic",
        "Default Task": "Default Task",
        // End Topics

        // Start Attributes
        "#separator#18": "Attributes",
        "Add attribute": "Add attribute",
        "Edit attribute": "Edit attribute",
        "Delete attribute": "Delete attribute",
        "Active attribute": "Active attribute",
        "Show attribute": "Show attribute",
        "Please enter title": "Please enter title",
        "Use First": "Use First",
        Avoid: "Avoid",
        "As Needed": "As Needed",
        Attribute: "Attribute",
        "DEGREE OF USE": "DEGREE OF USE",
        // End Attributes

        // Start Website CMS
        "#separator#19": "Website CMS",
        "Add website": "Add website",
        "Edit CMS": "Edit CMS",
        "Delete CMS": "Delete CMS",
        "Active CMS": "Active CMS",
        "Please enter Label": "Please enter Label",
        // End Website CMS

        // Start Roles & Permissions
        "#separator#20": "Roles & Permissions",
        "Edit Roles & Roles Permissions": "Edit Roles & Roles Permissions",
        "Select Role": "Select Role",
        "Add Role": "Add Role",
        "Update Role": "Update Role",
        "Delete Role": "Delete Role",
        "Role Name": "Role Name",
        "Update Role & Permissions": "Update Role & Permissions",
        "Write a name": "Write a name",
        "Save Role": "Save Role",
        "View log": "View log",
        "New Log": "New Log",
        "Old Log": "Old Log",
        // End Roles & Permissions

        // Start Languages
        "#separator#21": "Languages",
        "Add Language": "Add Language",
        "Edit Language": "Edit Language",
        "Delete Language": "Delete Language",
        "Active Language": "Active Language",
        "Active RTL": "RTL",
        "Active LTR": "LTR",
        "Please enter language": "Please enter language",
        // End Languages

        // Start TASK CATEGORIES
        "#separator#22": "Task Categories",
        "Category Name": "Category Name",
        "Show category": "Show category",
        "Edit category": "Edit category",
        "Delete category": "Delete category",
        "Add Task": "Add Task",
        "Show task": "Show task",
        "Edit task": "Edit task",
        "Delete task": "Delete task",
        "Write Name": "Write Name",
        "Write description": "Write description",
        "Task Details": "Task Details",
        // End TASK CATEGORIES

        // Start Registration
        "#separator#23": "Registration Page",
        Login: "Login",
        "Reset your password": "reset your password",
        "Login text":
          "If you are logging in to the new site for the first time, you will need to",
        "Enter your password": "Enter your password",
        "Forgot your password": "Forgot your password",
        "Enter your email": "Enter your email",
        "Don’t have an account": "Don’t have an account?",
        "Sign Up": "Sign Up",
        "Forgot Password": "Forgot Password",
        "Send Email": "Send Email",
        "Sign up text one":
          "In order to take full advantage of the Personal Learning Coach, you will first need to complete the Learning Connections Inventory (LCI).",
        "Sign up text two":
          "To access the Let Me Learn platform you must either belong to an institution and provide your institution's code OR you can purchase your own subscription with a credit card.",
        "Sign up text three":
          "Individual subscriptions come with a FREE 14-day trial period! You won't be charged until your trial period has ended.",
        Semester: "Semester",
        "Four months": "four months",
        "Four months price": "$14.99",
        Annual: "Annual",
        "One year": "one year",
        "One year price": "$39.99",
        "Verification code": "verification code",
        Register: "Register",
        "Age Range": "Age Range",
        City: "City",
        "Zip Code": "Zip Code",
        "CVC Code": "CVC Code",
        "Institutions Code": "Institutions Code",
        "Create Account": "Create Account",
        Back: "Back",
        individual: "individual",
        student: "student",
        "Small People": "6-12 years old",
        "Young People": "13 -21 years old",
        "Old People": "22 or older",
        "what are your patterns?": "what are your patterns?",
        "welcome back": "Welcome back! Please enter your details.",
        Coaching: "Coaching",
        "Coaching Price": "$400",
        // End Registration

        // Start Filters And Pagination
        "#separator#24": "Filters And Pagination",
        Show: "Show",
        "Per page": "Per page",
        Entries: "Entries",
        Showing: "Showing",
        to: "to",
        of: "of",
        Actions: "Actions",
        Import: "Import",
        Export: "Export",
        "Bulk Temp": "Bulk Temp",
        "Import Bulk": "Import Bulk",
        Filter: "Filter",
        Search: "Search",
        "Select status": "Select status",
        "Date from": "Date from",
        "Date to": "Date to",
        "Select action": "Select action",
        Items: "items",
        Remove: "Remove",
        Reset: "Reset",
        "no Item": "no Item",
        Items: "items",
        // End Filters And Pagination

        // Start Teams Page
        "#separator#25": "Teams",
        "Card One Title": "Tell who you are",
        "Card Two Title": "Enter the individuals",
        "Card Three Title": "Adjust your individuality",
        "Card Four Title": "Manage your behaviors",
        "Add New Team": "Add New Team",
        "Team members count": "Team members count",
        "Show team details": "Show team details",
        "Add team members": "Add team members",
        "Edit team name": "Edit team name",
        "Delete team": "Delete team",
        "Leave team": "Leave team",
        "Cancel invitation": "Cancel invitation",
        "Reinvite Member": "Reinvite Member",
        "Back to team": "Back to team",
        "Build New Team": "Build New Team",
        "Why this is important": "Why is this important?",
        "How will this work": "How will this work?",
        "What should I expect": "What should I expect?",
        "Reinvite members by email": "Or reinvite members by email",
        "Add Team": "Save Team",
        "Show Team Line Graph": "Show Team Line Graph",
        "Team Line Graph": "Team Line Graph",
        "Building the team": "Building the team",
        "Working together": "Working together",
        "Talking project": "Talking project",
        "Choose Team": "Choose Team",
        "Please select team": "Please select team",
        "How i will work with this team?": "How i will work with this team?",
        "working together statement":
          "Some text for testing some text for testing bla bla bla",
        "This team is less than 3 members": "This team is less than 3 members",
        "Please introduce yourself": "Please introduce yourself",
        "Add A Team": "Add Team",
        "Edit Team": "Edit Team",
        "Add Members": "Add Members",
        "complete team questions": "complete team questions",
        //// new  in team
        "Give your team a name": "Give your team a name",
        "Team Description": "Team Description",
        previous: "Previous",
        next: "Next",
        // End Teams Page

        // Start PLC Tables
        "#separator#30": "PLC Tables",
        Name: "Name",
        Email: "Email",
        Topic: "Topic",
        Description: "Description",
        "First Name": "First Name",
        "Last Name": "Last Name",
        Patterns: "Patterns",
        Category: "Category",
        Location: "Location",
        Organization: "Organization",
        Type: "Type",
        Score: "Score",
        Expiration: "Expiration",
        "Total Seats": "Total Seats",
        Remaining: "Expiration",
        ABE: "ABE",
        Code: "Code",
        "Contact INFO": "Contact INFO",
        Title: "Title",
        Answers: "Answers",
        Pattern: "Pattern",
        State: "State",
        Text: "Text",
        Classification: "Classification",
        Priority: "Priority",
        Word: "Word",
        Section: "Section",
        "Default Value": "Default Value",
        Alternative: "Alternative",
        Action: "Action",
        Date: "Date",
        Model: "Model",
        RTL: "RTL",
        Published: "Published",
        Unpublished: "Unpublished",
        // End PLC Tables

        // Start Patterns
        "#separator#31": "PLC Patterns",
        Sequence: "Sequence",
        precision: "Precision",
        "Technical Reasoning": "Technical Reasoning",
        Confluence: "Confluence",
        "Sequence shortcut": "S",
        "precision shortcut": "P",
        "Technical Reasoning shortcut": "TR",
        "Confluence shortcut": "C",
        "Sequence Case":
          "Hey, you have enough Sequence to complete this task. But you might want to check out these strategies to keep your Sequence pattern from taking over.",
        first: "first.",
        ///////////// start new patterns -- profile
        //? Use First
        "Sequence Use First":
          "Use First - Since my scale score for Sequence is between 25-35, I use Sequence first.",
        "Precision Use First":
          "Use First - Since my scale score for Precision is between 25-35, I use Precision first.",
        "TR Use First":
          "Use First - Since my scale score for Technical Reasoning is between 25-35, I use Technical Reasoning first.",
        "Confluence Use First":
          "Use First - Since my scale score for Confluence is between 25-35, I use Confluence first.",
        //? As Needed
        "Sequence As Needed":
          "As Needed - Since my scale score for Sequence is between 18-24, I use Sequence as needed.",
        "Precision As Needed":
          "As Needed - Since my scale score for Precision is between 18-24, I use Precision as needed.",
        "TR As Needed":
          "As Needed - Since my scale score for Technical Reasoning is between 18-24, I use Technical Reasoning as needed.",
        "Confluence As Needed":
          "As Needed - Since my scale score for Confluence is between 18-24, I use Confluence as needed.",
        //? Avoid
        "Sequence Avoid":
          "Avoid - Since my scale score for Sequence is between 7-17, I avoid Sequence",
        "Precision Avoid":
          "Avoid - Since my scale score for Precision is between 7-17, I avoid Precision",
        "TR Avoid":
          "Avoid - Since my scale score for Technical Reasoning is between 7-17, I avoid Technical Reasoning",
        "Confluence Avoid":
          "Avoid - Since my scale score for Confluence is between 7-17, I avoid Confluence",
        ///////////// start new patterns -- profile

        // End Patterns

        // Use Your Patterns Strategies
        "#separator#32": "Use Your Patterns (Strategies)",
        "Sequence - Use First":
          "Hey, you have enough Sequence to complete this Personal Strategies. But you might want to check out these strategies to keep your Sequence from taking over.",
        "Precision - Use First":
          "Hey, you have enough Precision to complete this Personal Strategies. But you might want to check out these strategies to keep your Precision from taking over.",
        "TR - Use First":
          "Hey, you have enough Technical Reasoning to complete this Personal Strategies. But you might want to check out these strategies to keep your Technical Reasoning from taking over.",
        "Confluence - Use First":
          "Hey, you have enough Confluence to complete this Personal Strategies. But you might want to check out these strategies to keep your Confluence from taking over.",

        "To complete this task, you need to INTENSIFY your Sequence. Please choose as many sentences below that would help you complete your task.":
          "To complete this Personal Strategies, you need to Intensify Your Sequence. Please choose as many sentences below that would help you complete your Personal Strategies.",
        "To complete this task, you need to INTENSIFY your Precision. Please choose as many sentences below that would help you complete your task.":
          "To complete this Personal Strategies, you need to Intensify Your Precision. Please choose as many sentences below that would help you complete your Personal Strategies.",
        "To complete this task, you need to INTENSIFY your Technical Reasoning. Please choose as many sentences below that would help you complete your task.":
          "To complete this Personal Strategies, you need to Intensify Your Technical Reasoning. Please choose as many sentences below that would help you complete your Personal Strategies.",
        "To complete this task, you need to INTENSIFY your Confluence. Please choose as many sentences below that would help you complete your task.":
          "To complete this Personal Strategies, you need to Intensify Your Confluence. Please choose as many sentences below that would help you complete your Personal Strategies.",

        "To complete this task, you have to TETHER your Sequence. Please choose as many sentences below that would help you complete your task.":
          "To complete this Personal Strategies, you have to TETHER your Sequence. Please choose as many sentences below that would help you complete your Personal Strategies.",
        "To complete this task, you have to TETHER your Precision. Please choose as many sentences below that would help you complete your task.":
          "To complete this Personal Strategies, you have to TETHER your Precision. Please choose as many sentences below that would help you complete your Personal Strategies.",
        "To complete this task, you have to TETHER your Technical Reasoning. Please choose as many sentences below that would help you complete your task.":
          "To complete this Personal Strategies, you have to TETHER your Technical Reasoning. Please choose as many sentences below that would help you complete your Personal Strategies.",
        "To complete this task, you have to TETHER your Confluence. Please choose as many sentences below that would help you complete your task.":
          "To complete this Personal Strategies, you have to TETHER your Confluence. Please choose as many sentences below that would help you complete your Personal Strategies.",

        "To complete this task, you need to FORGE your Sequence. Please choose as many strategies below that would help you complete your task.":
          "To complete this Personal Strategies, you need to Forge your Sequence. Please choose as many strategies below that would help you complete your Personal Strategies.",
        "Precision Forge":
          "To complete this Personal Strategies, you need to type your Precision. Please choose as many strategies below that would help you complete your Personal Strategies.",
        "TR Forge":
          "To complete this Personal Strategies, you need to type your Technical Reasoning. Please choose as many strategies below that would help you complete your Personal Strategies.",
        "Confluence Forge":
          "To complete this Personal Strategies, you need to type your Confluence. Please choose as many strategies below that would help you complete your Personal Strategies.",

        "Sequence No Term":
          "Congratulations! Since there is no Sequence required in this Personal Strategies and you avoid Sequence, no strategies are needed.",
        "Precision No Term":
          "Congratulations! Since there is no Precision required in this Personal Strategies and you avoid Precision, no strategies are needed.",
        "TR No Term":
          "Congratulations! Since there is no Technical Reasoning required in this Personal Strategies and you avoid Technical Reasoning, no strategies are needed.",
        "Confluence No Term":
          "Congratulations! Since there is no Confluence required in this Personal Strategies and you avoid Confluence, no strategies are needed.",
        // End Your Patterns Strategies

        // Start PLC Dialogs
        "#separator#33": "PLC Dialogs Buttons",
        Save: "Save",
        Cancel: "Cancel",
        Send: "Send",
        Submit: "Submit",
        Close: "Close",
        Delete: "Delete",
        Ok: "Ok",
        // End PLC Dialogs

        // Start Global Words
        "#separator#34": "PLC Global Messages",
        "update your plan": "Kindly update your plan to access this page",
        "Renew Subscription": "Renew Subscription",
        "Subscription Expired": "Your subscription expires after",
        "Go To LCI": "Go To LCI",
        Done: "Done",
        Deleted: "Deleted",
        "Are You Sure": "Are You Sure?",
        "Forgot Password Confirmation":
          "Email Sent, please check your email for further information",
        // End Global Words

        // test route names
        "#separator#35": "PLC Pages Title",
        Home: "Home",
        "Edit Profile": "Edit Profile",
        "User Profile": "User Profile",
        Questions: "Questions",
        Question: "Question",
        Roles: "Roles",
        "Category Tasks": "Category Tasks",

        // sequential pattern description
        "#separator#36": "sequential pattern description",
        "SEQUENTIAL LEARNING PATTERNS": "SEQUENTIAL LEARNING PATTERNS",
        "Organizes life": "Organizes life",
        "Makes Lists": "Makes Lists",
        "Lives by schedules": "Lives by schedules",
        "Makes extensive plans": "Makes extensive plans",

        // precision pattern description
        "#separator#37": "precision pattern description",
        "PRECISION LEARNING PATTERNS": "PRECISION LEARNING PATTERNS",
        "Relishes information": "Relishes information",
        "Seeks facts": "Seeks facts",
        "Must be right": "Must be right",
        "Provides detailed responses": "Provides detailed responses",

        // TECHNICAL pattern description
        "#separator#38": "tr pattern description",
        "TECHNICAL LEARNING PATTERNS": "TECHNICAL LEARNING PATTERNS",
        "Solves problems": "Solves problems",
        "Uses few words": "Uses few words",
        "Looks for relevance and practicality":
          "Looks for relevance and practicality",
        "Works alone": "Works alone",

        // TECHNICAL pattern description
        "#separator#39": "confluence pattern description",
        "CONFLUENCE LEARNING PATTERNS": "CONFLUENCE LEARNING PATTERNS",
        "Takes risks": "Takes risks",
        "Sees the big picture": "Sees the big picture",
        "Embraces innovation": "Embraces innovation",
        "Initiates change": "Initiates change"
      }
    };
  },
  components: {
    Card
  },
  mounted() {
    this.Refresh();

    this.handlerForDefaultLocalization();
    // this.$store
    //   .dispatch("cms/getEveryLocalization", {
    //     id: null
    //   })
    //   .then(res => {
    //     this.mainLocalization = this.Localization;
    //     this.localization_id = res.localization.id;
    //   });
  },
  computed: {
    languages: function() {
      return this.$store.getters["cms/languages"];
    },
    localizationList() {
      return this.$store.getters["cms/getLocalization"];
    },
    Localization() {
      return this.$store.getters["cms/getShowLocalization"];
    },
    checkLocalizationId() {
      return this.localization_id != null ? this.localization_id : 2;
    },
    loadingValue() {
      return this.$store.getters["cms/userLoad"];
    },
    import_src() {
      return `${process.env.VUE_APP_BASE_URL}/localization/import/${this.localization_id}`;
    }
    // localization/import/21
  },
  methods: {
    handlerForDefaultLocalization() {
      this.isLoading = true;
      this.$store
        .dispatch("cms/getEveryLocalization", {
          id: null
        })
        .then(res => {
          this.mainLocalization = this.Localization;
          this.localization_id = res.localization.id;
          this.isLoading = false;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getLocalizationFromEveryTab(obj) {
      this.isLoading = true;
      this.localization_id = obj.$vnode.key;
      this.$store
        .dispatch("cms/getEveryLocalization", {
          id: this.checkLocalizationId
        })
        .then(() => {
          this.mainLocalization = this.Localization;
          this.localization_id = obj.$vnode.key;
          this.isLoading = false;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    // Get Localization From Every Tab When Select Tab
    Refresh(query) {
      this.isLoading = true;
      this.$store.dispatch("cms/getLocalization", { query: query });
      this.$store.dispatch("cms/GetCMS", { query: query });
      this.isLoading = false;
    },
    language(languages, id) {
      let language = languages.filter(el => el.id == id)[0];
      if (language) {
        return language.title;
      }
      return "-";
    },
    save() {
      // let objData = this.localizationTranslationObject;
      this.processing = true;
      let objData = {};
      this.mainLocalization.forEach(element => {
        objData[element.key] = element.value;
      });
      this.$store
        .dispatch("cms/EditLocalization", {
          id: this.checkLocalizationId,
          query: {
            localization: objData
            // language_id
          }
        })
        .then(() => {
          this.$store
            .dispatch("cms/getEveryLocalization", {
              id: this.checkLocalizationId
            })
            .then(() => {
              this.mainLocalization = this.Localization;
              this.$store.dispatch(
                "roles/getAuthUserRolesPermissions",
                this.auth().id
              );
              this.processing = false;
              Swal.fire({
                title: "",
                text: "Done",
                type: "success",
                confirmButtonClass: "btn btn-success",
                buttonsStyling: false,
                icon: "success"
              });
            });
        });
    },
    exportLocalization() {      
      let getlanguageId = "";
      let getlanguageName = "";
      this.localizationList.filter(item => {
        if (item.id == this.localization_id) {
          getlanguageId = item.id;
        }
      });
      this.localizationList.filter(lang => {
        if (lang.id == getlanguageId) {
          getlanguageName = lang.language;
        }
      });
      this.$store
        .dispatch("cms/exportMainLocalization", {
          id: this.localization_id,
          localization_language_name: getlanguageName
        })
        .then(() => {
          Swal.fire({
            title: "",
            text: "Done",
            type: "success",
            confirmButtonClass: "btn btn-success",
            buttonsStyling: false,
            icon: "success"
          });
        });
    },
    RefreshLocalizationForImport() {
      this.isLoading = true;
      this.$store
        .dispatch("cms/getEveryLocalization", {
          id: this.localization_id
        })
        .then(() => {
          this.mainLocalization = this.Localization;
          Swal.fire({
            title: "",
            text: "Done",
            type: "success",
            confirmButtonClass: "btn btn-success",
            buttonsStyling: false,
            icon: "success"
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    beforeUpload(file) {
      this.isLoading = true;
    }
  }
};
//
</script>

<style lang="scss" scoped>
.text_area_for_localization {
  width: 100%;
  height: 120px;
  line-height: 1.7 !important;
  padding: 10px 15px;
  resize: none;
  color: #606266;
  width: 100%;
  border: 1px solid #dcdfe6;
  outline: none;
  transition: 0.3s;
  border-radius: 4px;
  word-break: break-word;
  &:focus {
    border-color: #11bfe3;
  }
  &::placeholder {
    color: rgba(#606266, 0.5);
    font-size: 14px;
    font-weight: 400;
  }
}
::v-deep .card label {
  text-transform: capitalize !important;
}

::v-deep .card label {
  text-transform: capitalize;
  margin-bottom: 0;
}

::v-deep .el-form {
  display: flex;
  flex-wrap: wrap;
  gap: 1%;
}

::v-deep .el-form-item__content {
  // background: #ccc;
  padding: 0;
}

.main_wrapping_of_inputs {
  width: 24% !important;
  &.long {
    width: 50% !important;
  }
  &.with_heading {
    width: 100% !important;
    text-align: center;
    text-transform: uppercase;
    box-shadow: 0 0 6px 0 rgba(#000, 0.2), 0 0 5px -2px rgba(#000, 0.1);
    .heading_separator {
      margin: 0 !important;
      padding: 12px 0;
      // font-size: 35px;
      // font-weight: bold;
      // letter-spacing: 5px;
      color: #777;
      // text-shadow: 1px 0px 1px #ccc, 0px 1px 1px #eee, 2px 1px 1px #ccc,
      //   1px 2px 1px #eee, 3px 2px 1px #ccc, 2px 3px 1px #eee, 4px 3px 1px #ccc,
      //   3px 4px 1px #eee, 5px 4px 1px #ccc, 4px 5px 1px #eee, 6px 5px 1px #ccc,
      //   5px 6px 1px #eee, 7px 6px 1px #ccc;
    }
  }
  // .heading_separator {
  //   color: red;
  //   background: red;
  // }
}

.tabs_button_rapper {
  gap: 10px;
  .tabs_button {
    border: none;
    border-bottom: 2px solid transparent;
    background: none;
    min-width: 70px;
    transition: 0.3s;
    background: #eee;
    &:hover,
    &.active {
      border-bottom: 2px solid green;
    }
  }
}
// .more_text {
//   height: 70px;
//   background: #eee;
// }
// ::v-deep .el-input__inner {
//   word-wrap: break-word;
//   white-space: pre-wrap;
// }
.label_custom_img {
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
  margin-bottom: 10px;
  transition: 0.3s all;
  background-color: #11bfe3;
  min-width: 144px;
  position: relative;
  height: 37px;
  &:hover {
    opacity: 0.8;
  }
  &.processing {
    pointer-events: none;
  }
  .loader_ocr {
    position: absolute;
    width: 25px;
    height: 25px;
    margin-bottom: 7px;
    left: calc(50% - 12.5px);
    top: 15%;
    border: 3px solid #fff;
    border-radius: 50%;
    animation: rotation 0.7s linear infinite;
    border-color: #fff #fff #fff transparent;
  }
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
::v-deep .export_import_buttons_wrapper {
  display: flex;
  justify-content: start;
  gap: 10px;
  padding: 0 0.7rem;
  .btn_wrapper {
    // border: 1px solid #ccc;
    border: none;
    border-radius: 3px;
    background-color: #eee;
    transition: 0.3s;
    color: #777;

    div{
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.export {
      &:hover {
        background-color: #11bfe3;
        color: #fff;
      }
    }
    &.import {
      &:hover {
        color: #fff;
        background-color: #3c3;
      }
    }
  }
  .localization_icon {
    padding: 5px;
    border-radius: 3px;
    font-size: 40px;
    cursor: pointer;
  }
}
</style>
